"use client"

import { FC } from "react"
import { color, font } from "./variables"

const factor = font.headingSizeFactor

export const GlobalStyles: FC = () => (
  <style jsx global>{`
    html,
    body {
      font-family: ${font.sans};
      font-size: ${font.size};
      font-weight: ${font.weight};
      line-height: ${font.lineHeight};
      color: ${color.text};
      word-break: break-word;
    }

    body {
      overflow-x: hidden;
    }

    a,
    button {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }

    h1,
    h2,
    h3 {
      display: block;
      margin: 1.5em 0 1em;
      font-weight: normal;
      text-transform: capitalize;
      line-height: ${font.lineHeightHeading};
    }

    h1 {
      color: ${color.brand};
      font-family: ${font.serif};
      font-size: calc(${factor} * 2rem + ${factor} * 2vmax);
    }
    h2 {
      font-size: calc(${factor} * 1.25rem + ${factor} * 1.25vmax);
    }
    h3 {
      font-size: calc(${factor} * 1rem + ${factor} * 1vmax);
    }

    p {
      margin: 0;
      margin-bottom: 1rem;
    }
  `}</style>
)
