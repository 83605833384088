"use client"

import { useServerInsertedHTML } from "next/navigation"
import React, { ReactNode, useState } from "react"
import { StyleRegistry, createStyleRegistry } from "styled-jsx"

// https://nextjs.org/docs/app/building-your-application/styling/css-in-js#styled-jsx
export default function StyledJsxRegistry({
  children,
}: {
  children: ReactNode
}) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [jsxStyleRegistry] = useState(() => createStyleRegistry())

  useServerInsertedHTML(() => {
    const styles = jsxStyleRegistry.styles()
    jsxStyleRegistry.flush()

    return <>{styles}</>
  })

  return <StyleRegistry registry={jsxStyleRegistry}>{children}</StyleRegistry>
}
